import { IMember, MemberStatus, Member } from "./projects/submodels/members.model";

export interface IProofreadingMember extends IMember {
    status: MemberStatus;
    status_date?: Date;
}


export class ProofreadingMember extends Member implements IProofreadingMember {
    status: MemberStatus;
    status_date?: Date;

    constructor(data: Partial<IProofreadingMember>) {
        super(data);
        this.init(data);
    }
}


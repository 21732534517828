import { BaseModel, Id } from "../../base.model";
import { required, prop, disable } from "@rxweb/reactive-form-validators";
import { IUser } from "../../users.model";


export enum MemberPermission {
    PROOFSCOPE_READ = 'PROOFSCOPE_READ',
    PROOFSCOPE_WRITE = 'PROOFSCOPE_WRITE',
    TEXT_READ = 'TEXT_READ',
    TEXT_WRITE = 'TEXT_WRITE',
    APPROVAL_INFO = 'APPROVAL_INFO',
    APPROVAL = 'APPROVAL',
    VERSIONS_READ = 'VERSIONS_READ',
    NOTIFICATIONS = 'NOTIFICATIONS',
    PREEMPTIVE_RIGHT = 'PREEMPTIVE_RIGHT'
}

export enum MemberStatus {
    PAUSED = 'paused',
    PENDING = 'pending',
    PROGRESS = 'inProgress',
    ACCEPT = 'accept',
    REJECT = 'reject',
    READ_ONLY = "readonly",
}

export enum MemberType {
    USER = 'USER',
    GROUP = 'GROUP'
}

export interface IMemberApprovers {
    _id: string,
    name: string,
    status: MemberStatus,
    status_date: Date;
}

export interface IMember {
    type: MemberType;
    name: string;
    usersID: Id[];
    users: IUser[];
    permissions: string[];
    hasProofscopeRead: boolean,
    hasProofscopeWrite: boolean,
    hasTextRead: boolean,
    hasTextWrite: boolean,
    hasApproInfo: boolean,
    hasApproDocument: boolean,
    hasVersionRead: boolean,
    hasNotification: boolean;
    hasPreemptiveRight?: boolean;
    order?: number;
    groupID?: Id;
    groupName?: string;
}

export class Member extends BaseModel<IMember> implements IMember {

    @required()
    name: string;

    @prop({ defaultValue: ['PROOFSCOPE_READ'] })
    permissions: string[] = ['PROOFSCOPE_READ'];

    @required()
    type: MemberType;

    @required()
    usersID: Id[];

    users: IUser[];

    @prop({ defaultValue: undefined })
    order: number | undefined;

    get emails() {
        return this.users?.map(u => u.email) || [];
    }
    
    constructor(data: Partial<IMember>) {
        super(data);
        this.init(data);
    }

    /** Checkbox interface */
    // PROOFSCOPE 
    @prop()
    set hasProofscopeRead(value: boolean) {
        if (value && !this.permissions.includes(MemberPermission.PROOFSCOPE_READ)) {
            this.permissions.push(MemberPermission.PROOFSCOPE_READ);
        }
        else if (!value && this.permissions.includes(MemberPermission.PROOFSCOPE_READ)) {
            this.permissions = this.permissions.filter(s => s !== MemberPermission.PROOFSCOPE_READ);
        }
    }

    get hasProofscopeRead() {
        return this.permissions.includes(MemberPermission.PROOFSCOPE_READ);
    }

    @prop()
    @disable({ conditionalExpression: () => true }) // this field is always true, always disabled
    set hasProofscopeWrite(value: boolean) {
        if (value && !this.permissions.includes(MemberPermission.PROOFSCOPE_WRITE)) {
            this.permissions.push(MemberPermission.PROOFSCOPE_WRITE);
        }
        else if (!value && this.permissions.includes(MemberPermission.PROOFSCOPE_WRITE)) {
            this.permissions = this.permissions.filter(s => s !== MemberPermission.PROOFSCOPE_WRITE);
        }
    }

    get hasProofscopeWrite() {
        return this.permissions.includes(MemberPermission.PROOFSCOPE_WRITE);
    }

    // TEXTS 

    @prop()
    set hasTextRead(value: boolean) {
        if (value && !this.permissions.includes(MemberPermission.TEXT_READ)) {
            this.permissions.push(MemberPermission.TEXT_READ);
        }
        else if (!value && this.permissions.includes(MemberPermission.TEXT_READ)) {
            this.permissions = this.permissions.filter(s => s !== MemberPermission.TEXT_READ);
        }
    }

    get hasTextRead() {
        return this.permissions.includes(MemberPermission.TEXT_READ);
    }

    @prop()
    set hasTextWrite(value: boolean) {
        if (value && !this.permissions.includes(MemberPermission.TEXT_WRITE)) {
            this.permissions.push(MemberPermission.TEXT_WRITE);
        }
        else if (!value && this.permissions.includes(MemberPermission.TEXT_WRITE)) {
            this.permissions = this.permissions.filter(s => s !== MemberPermission.TEXT_WRITE);
        }
    }

    get hasTextWrite() {
        return this.permissions.includes(MemberPermission.TEXT_WRITE);
    }

    // APPROBATION

    @prop()
    set hasApproInfo(value: boolean) {
        if (value && !this.permissions.includes(MemberPermission.APPROVAL_INFO)) {
            this.permissions.push(MemberPermission.APPROVAL_INFO);
        }
        else if (!value && this.permissions.includes(MemberPermission.APPROVAL_INFO)) {
            this.permissions = this.permissions.filter(s => s !== MemberPermission.APPROVAL_INFO);
        }
    }

    get hasApproInfo() {
        return this.permissions.includes(MemberPermission.APPROVAL_INFO);
    }

    @prop()
    set hasApproDocument(value: boolean) {
        if (value && !this.permissions.includes(MemberPermission.APPROVAL)) {
            this.permissions.push(MemberPermission.APPROVAL);
        }
        else if (!value && this.permissions.includes(MemberPermission.APPROVAL)) {
            this.permissions = this.permissions.filter(s => s !== MemberPermission.APPROVAL);
        }
        this.hasTextWrite = value;

    }

    get hasApproDocument() {
        return this.permissions.includes(MemberPermission.APPROVAL);
    }

    // VERSION

    @prop()
    set hasVersionRead(value: boolean) {
        if (value && !this.permissions.includes(MemberPermission.VERSIONS_READ)) {
            this.permissions.push(MemberPermission.VERSIONS_READ);
        }
        else if (!value && this.permissions.includes(MemberPermission.VERSIONS_READ)) {
            this.permissions = this.permissions.filter(s => s !== MemberPermission.VERSIONS_READ);
        }
    }

    get hasVersionRead() {
        return this.permissions.includes(MemberPermission.VERSIONS_READ);
    }

    // EMAIL

    @prop()
    set hasNotification(value: boolean) {
        if (value && !this.permissions.includes(MemberPermission.NOTIFICATIONS)) {
            this.permissions.push(MemberPermission.NOTIFICATIONS);
        }
        else if (!value && this.permissions.includes(MemberPermission.NOTIFICATIONS)) {
            this.permissions = this.permissions.filter(s => s !== MemberPermission.NOTIFICATIONS);
        }

    }

    get hasNotification() {
        return this.permissions.includes(MemberPermission.NOTIFICATIONS);
    }

    @prop()
    set hasPreemptiveRight(value: boolean) {
        if (value && !this.permissions.includes(MemberPermission.PREEMPTIVE_RIGHT)) {
            this.permissions.push(MemberPermission.PREEMPTIVE_RIGHT);
        }
        else if (!value && this.permissions.includes(MemberPermission.PREEMPTIVE_RIGHT)) {
            this.permissions = this.permissions.filter(s => s !== MemberPermission.PREEMPTIVE_RIGHT);
        }

    }

    get hasPreemptiveRight() {
        return this.permissions.includes(MemberPermission.PREEMPTIVE_RIGHT);
    }

    public hasCurrentOrder(current_order: number): boolean {
        return this.permissions.includes(MemberPermission.APPROVAL) || (this.order || 0) === current_order;
    }
}

export const environment = {
    production: true,
    type: "prod",
    api: {
        url: 'https://api.liveco.diadem-group.com',
        domain: 'diadem-group.com'
    },
    slug: 'liveco',
    transport: 'socket',
    cfURL: 'https://cloudflow.diadem-group.com',
    kioskURL: 'https://liveco.diadem-group.com/portal.cgi',
    baseRouteLogin: '/login/login.html',
    baseRoute: 'LiveCo/',
    isLocal: false,
    easydiadem: {
        pdf_repository: {
            cf_upload_path: "/portal.cgi?asset=upload_file&url=cloudflow%3A%2F%2FPP_FILE_STORE%2FPublic%2FPDF%2F&overwrite=true",
            file_upload_api: "https://tmpfiles.org/api/v1/upload",
            pathSearchProject: "custom.easydiadem.pdf_repository.pr_id",
            collectionPath: "ED_proofreadings"
        },
        text_correction: {
            cf_upload_path: "/portal.cgi?asset=upload_file&url=cloudflow%3A%2F%2FPP_FILE_STORE%2FPublic%2FCORRECTIONS%2F&overwrite=true",
        }
    },
    whitepapers: {
        livecore: "LIVECO_CORE",
        proofreading: "PR_Formulaire",
        projectCreation: "PC_Formulaire",
        printers: "PRINTER_Formulaire",
        users: "USER_Formulaire",
        constances: "SD_CONSTANCES",
        colorBooks: "COLORBOOKS_Formulaire",
        easydiadem: {
            core: "ED_CORE_BUSINESS",
            ED_Depot_PDF: "ED_Depot_PDF",
            ED_Text_Correction: "ED_Text_Correction"
        },
        fetchL2pubBdd: "SD_FETCH_L2PUB_BDD",
        proofreadingIntern: "SF_Relecture_Interne",
        diademCartouche: "Diadem_Preview_Cartouche"

    },
    regex_xraid_server: /^\/\/XRAID-SERVEUR/,
    regex_srv_data_01: /^\/\/SRV-DATA-01/,
    volumes_mac: "/Volumes",
    cloudflow_url_base: "cloudflow://Production_1",
    archives: {
        windows: "\\\\SRV-DATA-01\\archives 2\\",
        mac: "/Volumes/archives 2/"
    },
    proofscope_placeholder: "/portal.cgi?proofscope&url=cloudflow%3a%2f%2fPP_FILE_STORE%2fPublic%2fForm%2fimg%2fdiadem-logo.ai&view_id=cdfae4df-4087-4ce0-b15e-9be542735a24&signature=5d5bf74e4af2825d6bc9d002abd4ab19"
};
import { BaseModel } from "../base.model";
import { Proofreading } from "../proofreading.model";
import { User } from "../users.model";
import { Member } from "./submodels/members.model";
import { IL2PUBProject, L2PUBProject } from "./submodels/l2pub-project.model";
import { GeneralInfo, IGeneralInfo } from "./submodels/general-info.model";
import { IGeneralChild, IEDEmbededRequest, IRiverflowEmbededData, GeneralChild } from "./submodels/general.model";
import { IPrinting, Printing } from "./submodels/printing.model";
import { IUploadedFile } from "src/app/services/file.service";

export enum State {
    LIVE = 'live',
    ARCHIVED = "archived",
}

export enum CFJobType {
    JOB_ENFANT = 'Job_Enfant',
    JOB_MAITRE = 'Job_Maitre'
}


export interface IProject {
    _id: string;
    birth: Date;
    type: string;
    state: string;
    name: string;
    folders: { url: string, tag: string; }[];
    identifier: string;
    description: string;
    project_id: string;
    custom: ICustomData;
    previews: any;
}

export interface ICustomData {
    general_info: IGeneralInfo;
}

// Child project
export interface IChildProject extends IProject {
    custom: IChildCustomData;
}

export interface IChildCustomData extends ICustomData {
    general: IGeneralChild;
    printing: IPrinting;
    l2pub: IL2PUBProject;
    colors: IColors;
    codes: ICodes;
    members: Member[];
    comments?: any;
    cartouche: ICartouche;
    crea: ICrea;
    exe: IExe;
    hd: IHd;
    repro: IRepro;
    easydiadem?: IEDEmbededRequest;
    proofreadings?: Proofreading[];
    riverflow?: IRiverflowEmbededData;
}


export interface IColors {
    impression_nombredecouleurmax: number;
    mec_reel: number;
    impression_couleur1: string;
    impression_couleur2: string;
    impression_couleur3: string;
    impression_couleur4: string;
    impression_couleur5: string;
    impression_couleur6: string;
    impression_couleur7: string;
    impression_couleur8: string;
    impression_couleur9: string;
    impression_couleur10: string;
    comm_seq_colors: string;
    impression_finition1: string;
    impression_finition2: string;
    impression_finition3: string;
    impression_finition4: string;
    impression_finition5: string;
    impression_finition6: string;
    impression_finition7: string;
    impression_finition8: string;
    impression_finition9: string;
    impression_finition10: string;
    id_refcommune: string;
    id_couleurcommune: string;
}


export interface ICrea {
    nom: string;
    type: string;
    validation_date: Date;
    commentaire_crea?:string;
}


export interface IGencod{
    type: string;
    code: string;
    couleur: string;
    taille: number;
    rlb: number;
}

export interface IQrcode{
    adresse: number;
    couleur: string;
    taille: number;
    correction: string;
    rlb: number;
}

export interface IDataMatrix {
    code: string;
    couleur: string;
    taille: string;
    rlb: string;
}


export interface ICodes {
    gencod: IGencod;
    qrcode: IQrcode;
    dataMatrix:IDataMatrix;

}


export interface ICartouche {
    operateur_exe: User;
    operateur_hd: User;
    operateur_repro: User;
    qualite_image: string;
    textMinPos: string;
    textMinNeg: string;
    filetMinPos: string;
    filetMinNeg: string;
    policeUsed: string;
    hautXMin: string;
    hautChiffrePoids: string;
    hautEMetro: string;
    url_exe?: string;
    url_repro?: string;
}

export interface IFileRepo {
    file: IUploadedFile;
    proofreadingID?:string;

}
export interface IPDFRepo {
    file_repo : IFileRepo[];
}

export interface IPdfRepository {
    pr_id: string,
    pr_status: string,
    pr_status_date: String,
}

export interface IInfoBase {
    id_master: string;
    id_archive: string;
    commentaire_gamme: string;
}


export interface IRepro {
    repro_fichier?: string[];
    commentaire_imprimeur?: string;
    commentaire_preparation?: string;
    commentaire_cpp?: string;
}

export interface IOther {
    comment?: string;
}



export interface IExe {
    text_brief?: boolean,
    text_archive?: boolean,
    text_crea?: boolean,
    text_fichetexteclient?: boolean,
    langue_nombre?: number,
    langue_ordre?: string[],
    commentaire_cpp?: string,
    comm_customer?: string,
    deliverables?: string[];
}

export interface IHd {
    hd_shoot: boolean,
    hd_shootclient: boolean,
    hd_shutter: boolean,
    hd_archive: boolean,
    hd_planningr0: Date,
    hd_planningr1: Date,
    hd_planningepson: Date,
    hd_planninglivraison: Date,
    commentaire_clienthd: string;
}


export class Project extends BaseModel<IProject> implements IProject {
    folders: { url: string, tag: string; }[];
    _id: string;
    birth: Date;
    type: CFJobType;
    state: string;
    name: string;
    identifier: string;
    description: string;
    project_id: string;
    custom: ICustomData;
    id_maitre: string;
    previews: any;

    constructor(data: Partial<IProject> = {}) {
        super(data);
        this.init(data);
    }
}

export class ChildCustomData extends BaseModel<IChildCustomData> implements IChildCustomData {
    protected __build: { [path: string]: new (data: any) => any; } = {
        'general_info': GeneralInfo,
        'general': GeneralChild,
        'printing': Printing,

        'l2pub': L2PUBProject,
        'proofreadings': Proofreading,
        'members': Member,
    };
    general_info: GeneralInfo = new GeneralInfo();
    general: GeneralChild = new GeneralChild();
    base: IInfoBase;
    printing: IPrinting = new Printing();
    l2pub: L2PUBProject;
    colors: IColors;
    codes: ICodes;
    members: Member[];
    comments?: any;
    cartouche: ICartouche;
    crea: ICrea;
    exe: IExe;
    hd: IHd;
    pdf_repo: IPDFRepo;
    other: IOther;
    repro: IRepro;
    easydiadem?: IEDEmbededRequest | undefined;
    proofreadings: Proofreading[] = [];
    riverflow?: IRiverflowEmbededData | undefined;

    constructor(data: Partial<IChildCustomData> = {}) {
        super(data);
        this.init(data);
    }
}

export class ChildProject extends Project {
    protected __build: { [path: string]: new (data: any) => any; } = {
        'custom': ChildCustomData,
    };
    type: CFJobType = CFJobType.JOB_ENFANT;
    custom: ChildCustomData = new ChildCustomData();
    id_maitre: string;

    constructor(data: Partial<IChildProject> = {}) {
        super(data);
        this.init(data);
    }
}
